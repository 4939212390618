import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch";
import Home from "./home";
import Layout2 from "./components/layout/Layout2";
import Layout from "./components/layout/Layout";
import { Layout3 } from "./components/layout";
import ProjectDetail from "./components/projects/projectDetail";
import ShowCaseDetail from "./components/showcase/showcaseDetail";
import ThankYouContact from "./components/hooks/thankyou";
import ShowCases from "./components/projects/showcase";
import SoftwareStack from "./components/softwarestack/softwarestack";
import SystemAdministration from "./components/hardwarestack/systemadministration";
import Aboutme from "./components/generalpages/aboutus";
import Contactme from "./components/generalpages/contact";
import Resources from "./components/generalpages/resources";

function AppRouter() {
  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>{" "}
        </>
        <>
          {/* Layout2 */}
          <Route path="/" element={<Layout2 />}>
            <Route
              path="/project/:projectId"
              element={<ProjectDetail />}
            ></Route>
            <Route
              path="/activity/:activityId"
              element={<ShowCaseDetail />}
            ></Route>
            <Route path="/resources" element={<Resources />} />
            <Route path="/contactme" element={<Contactme />} />
            <Route path="/aboutme" element={<Aboutme />} />
            <Route path="/thankyou" element={<ThankYouContact />} />
            <Route path="/techstack" element={<SoftwareStack />} />
            <Route path="/hardwaresupport" element={<SystemAdministration />} />
            <Route path="/projects" element={<ShowCases />} />
            <Route path="*" element={<Nomatch />} />
          </Route>
        </>
        <>
          {/* Layout3 */}
          <Route path="/" element={<Layout3 />}></Route>
        </>
      </Routes>
    </>
  );
}

export default AppRouter;
