import React from "react";
import Styles from "./styles.module.css";

import ListedShowcase from "./listedshowcase";

function Projects() {
  return (
    <div className={Styles.container}>
      <div className={Styles.subcontainer}>
        <div className={Styles.header}>
          <h1>My web development projects portfolio</h1>
          <p>
            As a full stack web developer, I've successfuly delivered a range of
            diverse web development projects, spanning from initial concept
            creation through to frontend design, coding, backend integration,
            testing, and deployment. Among the projects I've successfully
            completed are e-commerce platforms, static websites, server-side
            rendering applications, single-page websites, institutional portals,
            content management systems (CMS), and management information
            systems.
          </p>
        </div>
        <ListedShowcase />
      </div>
    </div>
  );
}

export default Projects;
