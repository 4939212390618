import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import { Zoom, Fade } from "react-awesome-reveal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const SystemAdministration = () => {
  return (
    <div className={Styles.productsContainer}>
      <div className={Styles.productCard}>
        <Zoom>
          <div className={Styles.bodyshowcase}>
            <h3>ICT Infrastructure Management</h3>

            <p>
              My proficiency extends to managing the entire spectrum of hardware
              components essential for smooth operations. I oversee server
              upgrades to enhance performance and implement network management
              solutions to ensure seamless connectivity. Additionally, I
              spearhead the setup and configuration of data centers,
              implementing best practices for efficient cooling and power
              distribution.
            </p>
            <img src="/images/devops.gif" alt="WebApps" />
            <Link to="activity/ictinfrastructure">
              <button className={Styles.infobtn}>Read More</button>
            </Link>
          </div>
        </Zoom>
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <div className={Styles.productCard}>
          <Fade>
            <div className={Styles.subContainer}>
              {data.map((item, index) => (
                <Link to={"/"} key={index}>
                  <li>
                    <CheckBoxIcon
                      style={{
                        margin: "1rem",
                        paddingRight: "1rem",
                        color: "var(--greyTop)",
                      }}
                    />{" "}
                    {"\t\t "} {item}
                  </li>
                </Link>
              ))}
            </div>{" "}
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default SystemAdministration;

const data = [
  "Windows Servers",
  "Linux Servers",
  "VMware Support",
  "DevOps",
  "Cloud Infrastructure Management ",
  "Virtual Cloud Networks",
  "Dockers",
  "Bash Scripts",
  "Network Management",
  "Disaster Recory & Backups ",
  "System Administration",
  "Technical Documentation",
  "System Audits",
];
