// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "About Me",
    path: "/aboutme",
  },
  {
    title: "Projects",
    path: "/projects",
  },
  {
    title: "Techstack",
    path: "/techstack",
  },
  {
    title: "Hardware Support",
    path: "/hardwaresupport",
  },
  {
    title: "Downloads",
    path: "/resources",
   
  },
  { title: "Contact", path: "/contactme" },
];

export default navLinks;
