import React from "react";

import Styles from "./contacts.module.css";

export default function ThankYouContact() {
  return (
    <div className={Styles.container}>
      <div className={Styles.thankyou}>
        <h3>Thank You</h3>
        <img src="images/johnmakolalogo.png" alt="John Makola" />
        <p>
          Thank you for reaching out. I do appreciate your interest in working
          with me.
        </p>
        <p>
          Let me review your request, and revert back to you shortly. If you
          have any immediate questions or concerns, feel free to reach out to me
          on <span>+ 254-721-447-666</span>. We look forward to working with
          you.
        </p>
        <h4>John Makola</h4>
        <p>
          <span>Email:</span>johnmakola@gmail.com
        </p>
        <p>
          <span>Address:</span>Nairobi, Kenya
        </p>
      </div>
    </div>
  );
}
