import React from "react";
import Styles from "./styles.module.css";
import ListedShowcase from "./listedshowcase";

function Showcase() {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
       
        <h1>About Me</h1>
        <p>
          My names are John Makola, For over a decade and a half, I've been helping organizations of all shapes and sizes, from international institutions like the UN to local governments and non-profits, navigate the ever-changing world of technology. I started by building the essential tools that keep things running smoothly, and over time, I've become an expert in managing the intricate systems behind cloud computing. This broad experience allows me to solve a wide range of IT challenges, whether it's designing user-friendly systems that make work more efficient or ensuring the complex machinery behind technology runs smoothly.  No matter the project, I approach it with a problem-solving mindset, ensuring technology becomes a seamless partner in achieving your goals
        </p>
      </div>
      <ListedShowcase />
    </div>
  );
}

export default Showcase;
