// Slider.js
import React, { useState, useEffect } from "react";
import "./Slider.css";


const Slider = ({SliderData}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === SliderData.length - 1 ? 0 : prevSlide + 1
      );
    }, 10000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [SliderData.length]);

  return (
    <div className="slider-container">
      <div className="slideImage">
        {SliderData.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={`${slide.link}`} alt={slide.alt} />
          </div>
        ))}
      </div>
      <div className="indicator-bar">
        {SliderData.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;

