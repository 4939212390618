import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles1.module.css";
import { ShowCases } from "./showcaseData";
import SkeletonVariant from "../hooks/SkeletonVariant";


function ListedShowcase({ props }) {
  const [showcase, setShowcase] = useState([]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));  
  useEffect(() => {
    const fetchData = async() => {
      try {
        await delay(2000);
        setShowcase(ShowCases);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={Styles.themesContainer}>
      {!showcase[0] ? (
        <>
          {data.map((_, index) => (
            <div key={index}>
              {" "}
              <SkeletonVariant />
            </div>
          ))}
        </>
      ) : (
        showcase.map((showcase, index) => (
          <div className={Styles.productCard} key={index}>
            <div className={Styles.favoriteContainer}>
              <div className={Styles.favouriteImage}>
                <Link to={`/activity/${showcase.activityId}`}>
                  <img src={showcase.image} alt="John Makola" />
                </Link>
              </div>
            </div>
            <div className={Styles.productInfo}>
              <h5>{showcase.title}</h5>
              <p>{showcase.description.substring(0, 120)}...</p>
            </div>
            <div className={Styles.addcart}>
              <div className={Styles.addcartSection}>
                <Link to={`/activity/${showcase.activityId}`}>
                  <button className={Styles.button}>Read More</button>
                </Link>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ListedShowcase;

const data = [1, 2, 3, 4];
