import React from "react";
import {  Outlet } from "react-router-dom";
import Styles from "./styles.module.css";

import Footer from "../footer";
import NavBar from "../navbar/navbar";

function Layout() {
  return (
    <div>
      <NavBar />
      <div className={Styles.container}>
       
        <div className={Styles.column4}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;

