import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";

export default function ConferenceDateContact() {
  return (
    <div className={Styles.highlight}>
      <h4>Conference Details </h4>
      <p>
        Inaugural International Multi-Sectoral Conference on Governance and
        Development in Kenya 2024
      </p>
      <h4>Date: </h4>
      <p>September, 4-6th, 2024</p>
      <h4>Venue: </h4>
      <p>Kenya School of Government</p>
      <h4>Location:</h4>
      <p>Nairobi County , Kenya</p>
      <h4>Principal Contact</h4>
      <p>Rinah Andayi </p>
      <p>Email: info@COMPANY NAME.org</p>
      <h4>Office Lines</h4>
      <p>+254-100-371-646 </p>

      <Link to="/conference registration">
        <button className={Styles.button}>Register Today</button>
      </Link>
    </div>
  );
}
