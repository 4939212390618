import React from "react";
import "./Styles.css";

function Footer() {

  return (
    <div className="mainContainer">
      <div className="category-container">
        <p> Copyright : &copy; {new Date().getFullYear()} John Makola</p>
      </div>
    </div>
  );
}

export default Footer;
