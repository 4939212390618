import React from "react";
import Styles from "./styles.module.css";
import MainMenu from "./mainmenu";
import MenuLinks from "./MenuLinks";

const NavBar = () => {
  return (
    <>
      <div className={Styles.Navbar}>
        <MainMenu />
      </div>
      <MenuLinks />
    </>
  );
};

export default NavBar;
