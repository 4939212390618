import React from "react";
import Styles from "../home.module.css";

export default function NoMatch() {
  return (
    <div className={Styles.bodyshowcase}>
      <h1>Page Not Found</h1>
      <img src="/images/webapps.png" alt="WebApps" />
    </div>
  );
}
