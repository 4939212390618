import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import {  AttentionSeeker } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.rightSide}>
      <div className={Styles.rightSideA}>
        <div className={Styles.rightSideAAA}>
          <Link to="/">
            <img src="images/johnmakolalogo.png" alt="John Makola" />
          </Link>
        </div>
        <div className={Styles.rightSideAAB}>
          <AttentionSeeker>
            <Link to="/">
              <h1>IT Operations Management & Software Development</h1>
            </Link>
          </AttentionSeeker>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
