import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles1.module.css";
import { ShowCases } from "./showcaseData";
import SkeletonVariant from "../hooks/SkeletonVariant";


function ListedShowcase() {
  const [project, setProject] = useState([]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await delay(2000);
        setProject(ShowCases);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={Styles.themesContainer}>
      {!project[0] ? (
        <>
          {data.map((_, index) => (
            <div key={index}>
              <SkeletonVariant />
            </div>
          ))}
        </>
      ) : (
        project.map((showcase, index) => (
          <div className={Styles.productCard} key={index}>
            <Link to={`/project/${showcase.projectId}`}>
              <img src={showcase.image} alt="John Makola" />{" "}
            </Link>
          </div>
        ))
      )}
    </div>
  );
}

export default ListedShowcase;
const data = [1, 2, 3, 4];
