const MainMenuLinks = [
  {
    id: "0003",
    menu: "Civil Society Organisations",
    link: "programs/Civil Society Organisations",
    section: "aboutus",
  },
  {
    id: "0004",
    menu: "Government & Private Sector",
    link: "programs/Government & Private Sector",
    section: "aboutus",
  },
  {
    id: "0005",
    menu: "Democracy & Political Parties",
    link: "programs/democracy & political parties",

    section: "aboutus",
  },
  {
    id: "0006",
    menu: "Research & Communication",
    link: "programs/Research & Communication",
    section: "aboutus",
  },
  {
    id: "0007",
    menu: "Special Trainings & Services",
    link: "programs/speacial trainings & services",
    section: "aboutus",
  },
];

export default MainMenuLinks;
