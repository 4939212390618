import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ShowCases } from "./showcaseData";
import ProjectStyles from "./projectstyles.module.css";

function ProjectDetail() {
  const [project, setProjects] = useState({});
  const { projectId } = useParams();

  useEffect(() => {
    const fetchData = () => {
      try {
        const projects = ShowCases.find((cat) => cat.projectId === projectId);
        setProjects(projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchData();
  });

  return (
    <div className={ProjectStyles.mainContainer}>
      <div className={ProjectStyles.header}>
        <h1>{project.title}</h1>
        <p>{project.description}</p>
        
      </div>
      <div>
        <iframe
          src={project.link}
          title={project.title}
          height="500"
          width="100%"
        ></iframe>
      </div>
    </div>
  );
}

export default ProjectDetail;
