const ShowCases = [
  {
    activityId: "ictinfrastructure",
    title: "ICT infrastructure Support Engineer",
    image: "/images/cover1.jpg",
    description:
      "Help Desk Support, Operating Systems, Network Administration, Cloud Solutions, Cyber Security, DevOPS",
    link: "ictinfrastructure",
    subtitle1: "Hardware Management:",
    subtitle2: "Software Application Support:",
    subtitle3: "Cloud Ecosystem Support: ",
    paragraph1:
      " I provide support and maintenance for on-premise computing resources from user computers, servers, printers and Networking equipment. I am proficient with troubleshooting ,patching and upgrading most common  organisational computer resources to tune up performance and enhance efficiency. ",
    paragraph2:
      " I provide comprehensive assistance to both users and IT support admins on  various operating systems, such Windows 10, 11 , Windows Server, macOS, and Linux distributions like Redhat, Ubuntu, and Suse. This includes troubleshooting system issues, optimizing performance, and ensuring software compatibility.",
    paragraph3:
      "My expertise extends to managing cloud environments, encompassing tasks such as deploying virtual servers (VMs), orchestrating Docker containers, OS images, and optimizing resource allocation. I am profiencent with working with: Azure, AWS and Oracle OCI.",
    imageProject: "/images/coverproject4.jpg",
  },
  {
    activityId: "softwaredevelopment",
    title: "Software Development",
    image: "/images/cover2.jpg",
    description:
      "UX/UI, Frontend and Backend, Web Sites, Web Apps, CMS, CRMs and MIS",
    link: "softwaredevelopment",
    subtitle1: "",
    subtitle2: "",
    subtitle3: "",
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    imageProject: "/images/coverproject3.jpg",
  },
  {
    activityId: "projectmanagement",
    title: "Project Management",
    image: "/images/cover3.jpg",
    description:
      "Project Implementantion Plans, Project Concepts, WorkPlans, Work Breakdown Structures, Precedence Tables, Gnatt Charts and Budgets ",
    link: "projectmanagement",
    subtitle1: "",
    subtitle2: "",
    subtitle3: "",
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    imageProject: "/images/coverproject2.jpg",
  },
  {
    activityId: "technicalwriting",
    title: "Technical Writing",
    image: "/images/cover4.jpg",
    description:
      "Concept Notes, Research Proposals, Desktop Reviews, Market Research, Technical Proposals, Technical Feasibility Studies",
    link: "technicalwriting",
    subtitle1: "",
    subtitle2: "",
    subtitle3: "",
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    imageProject: "/images/coverproject1.jpg",
  },
];

export { ShowCases };
