import React from "react";
import Showcase from "./components/showcase/showcase";
import BodyShowcase from "./components/bodyshowcase/bodyshowcase";
import Projects from "./components/projects/showcase";
import SoftwareStack from "./components/softwarestack/softwarestack";
import SystemAdministration from "./components/hardwarestack/systemadministration";
import Styles from "./home.module.css"
function Home() {
  return (
    <div className={Styles.container}>
      <Showcase />
      <BodyShowcase />
      <Projects />
      <SoftwareStack />
      <SystemAdministration />
    </div>
  );
}

export default Home;
