import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ShowCases } from "./showcaseData";
import ShowCaseStyles from "./showcase.module.css";
import ContactForm from "../hooks/contact";

function ShowCaseDetail() {
  const [project, setProjects] = useState({});
  const { activityId } = useParams();

  useEffect(() => {
    const fetchData = () => {
      try {
        const projects = ShowCases.find((cat) => cat.activityId === activityId);
        setProjects(projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchData();
  });

 

  return (
    <div className={ShowCaseStyles.mainContainer}>
      <div className={ShowCaseStyles.header}>
        <div className={ShowCaseStyles.imgProject}>
          <img src={project.imageProject} alt={project.id} />
        </div>
        <h1>{project.title}</h1>
        <p style={{ fontWeight: "bold" }}>{project.description}</p>
        <div>
          <p style={{ textAlign: "left" }}>
            <span>{project.subtitle1 === "" ? "" : project.subtitle1}</span>
            {project.paragraph1 === "" ? "" : project.paragraph1}
          </p>
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            <span>{project.subtitle2 === "" ? "" : project.subtitle2}</span>
            {project.paragraph2 === "" ? "" : project.paragraph2}
          </p>
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            <span>{project.subtitle3 === "" ? "" : project.subtitle3}</span>
            {project.paragraph3 === "" ? "" : project.paragraph3}
          </p>
        </div>
      </div>
      <div>
        <ContactForm />
      </div>
    </div>
  );
}

export default ShowCaseDetail;
