const ShowCases = [
  {
    projectId:"1",
    title: "AHADI Gorvernance Research Consultants[AGREC]",
    image: "/images/project1.jpg",
    description:
      "An Organisational Website for AGREC. A Research and Public Governance firm that strives to redefine the landscape of Governance and Development by implement Good Governance practices.",
    link: "https://agrec.org",
  },
  {
    projectId:"2",
    title: "ShamiriVeg",
    image: "/images/project2.jpg",
    description:
      "An E-commerce website for an Agri-Tech firm. ShamiriVeg provides linkages to farmers with the supply chain players by letting them piggy ride on their platform",
    link: "https://shamiriveg.com",
  },
  {
    projectId:"3",
    title: "Aspire Graphics",
    image: "/images/project3.jpg",
    description:
      "Aspire Graphics is an IT Services Firm based in Nairobi, Kenya. It provides branding and IT Help Desk Management Services ",
    link: "https://aspiregraphics.co.ke",
  },
  {
    projectId:"4",
    title: "Simple Simon Safaris",
    image: "/images/project4.jpg",
    description:
      "A Travel Blog website. The firm organises and  trips for outdoor hiking  activities in Kenya: ex",
    link: "https://simplesimonsafaris.com",
  },
];

export { ShowCases };
