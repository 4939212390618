import React from "react";
import { Outlet } from "react-router-dom";
import Styles from "./styles.module.css";

import Footer from "../footer";
import NavBar from "../navbar/navbar";
import MainMenuLinks from "../footer/mainmenulinks";
import ConferenceDateContact from "./conferencedatecontact";

function Layout3() {
  return (
    <div>
      <NavBar />
      <div className={Styles.container}>
        <div className={Styles.column4}>
          <Outlet />
        </div>
        <div className={Styles.column6}>
          <h4>Quick Links</h4>
          {MainMenuLinks.map((menulink, index) => (
            <li key={index}>{menulink.menu}</li>
          ))}{" "}
          <ConferenceDateContact />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout3;
