import React from "react";
import Styles from "./Styles.module.css";
import { Zoom, Fade } from "react-awesome-reveal";
import Slider from "../hooks/slider/Slider";

const BodyShowcase = () => {
  return (
    <div className={Styles.productsContainer}>
      <div className={Styles.productCard}>
        <Zoom>
          <div className={Styles.bodyshowcase}>
            <p>
              Apart from solving IT problems, i'm fun loving guy and i do enjoy spending quality time with my family,friends and professional networking. I am an amateur Tennis player and an avid Hiker (explorer).
             I also do mentor youth on Digital Enterprenurship 
              and Investments Skills.
            </p>
          </div>
        </Zoom>
      </div>
      <div style={{justifyContent:"center", alignItems:"center", padding:"1rem"}}>
        <div className={Styles.productCard}>
          <Fade>
            <Slider SliderData={images} />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default BodyShowcase;

const images = [
  { itemId: 3, link: "/images/outdoor.jpg" },
  { itemId: 1, link: "/images/outdoor1.jpg" },
  { itemId: 2, link: "/images/outdoor2.jpg" },
  { itemId: 3, link: "/images/outdoor3.jpg" },
];
