import React from "react";
import Styles from "./styles.module.css";
import ContactForm from "../hooks/contact";


function Resources() {
  return (
    <div className={Styles.container}>
      <div className={Styles.subcontainer}>
        <div className={Styles.header}>
          <h1>Curriculum Vitae</h1>
          <div>
            <iframe
              src={"John_Makola_Curriculum_Vitae.pdf"}
              title="CV"
              height="500"
              width="95%"
            ></iframe>
          </div>
          <h1>Get intouch</h1>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Resources;
