import React from "react";
import Styles from "./styles.module.css";
import ContactForm from "../hooks/contact";
import { Fade } from "react-awesome-reveal";

function Contactme() {
  return (
    <div className={Styles.container}>
      <div className={Styles.subcontainer}>
        <div className={Styles.header}>
          <h1>Get intouch</h1>

          <Fade>
            <img src="images/outdoor.jpg" alt="John Makola" />
          </Fade>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contactme;
