import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import { Zoom, Fade } from "react-awesome-reveal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const SoftwareStack = () => {
  return (
    <div className={Styles.productsContainer}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <div className={Styles.productCard}>
          <Fade>
            <div className={Styles.subContainer}>
              {data.map((item, index) => (
                <Link to={"/"} key={index}>
                  <li>
                    <CheckBoxIcon
                      style={{
                        margin: "1rem",
                        paddingRight: "1rem",
                        color: "var(--greyTop)",
                      }}
                    />{" "}
                    {"\t\t "} {item}
                  </li>
                </Link>
              ))}
            </div>{" "}
          </Fade>
        </div>
      </div>
      <div className={Styles.productCard}>
        <Zoom>
          <div className={Styles.bodyshowcase}>
            <h3>Software Development Technology Stack</h3>
            <p>
              As a full-stack developer, I bring a wealth of experience in a
              wide array of programming technologies, frameworks, and libraries
              essential for crafting sophisticated web applications like this one. Proficient
              in HTML and CSS, I specialize in creating visually captivating and
              responsive user interfaces. My expertise extends to Java for
              server-side development, leveraging frameworks like Spring Boot
              and Hibernate to ensure scalability and efficiency.
            </p>
            <img src="/images/webapps.png" alt="WebApps" />

            <Link to="activity/softwaredevelopment"><button className={Styles.infobtn}>Read More</button></Link>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default SoftwareStack;

const data = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "ReactJS",
  "NextJS",
  "VUE.JS",
  "React Native",
  "DJANGO",
  "SQL",
  "MS-SQL",
  "MONGODB",
  "POSTGRESQL",
];
