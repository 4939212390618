import React from "react";
import Styles from "./styles.module.css";
import { Fade } from "react-awesome-reveal";

function Aboutme() {
  return (
    <div className={Styles.container}>
      <div className={Styles.subcontainer}>
        <div className={Styles.header}>
          <h1>About Me</h1>
          <Fade>
            <img src="images/outdoor.jpg" alt="John Makola" />
          </Fade>
          <p>
            My names are John Makola, an IT expert and entrepreneur with more
            than 15 years of expertise in designing, constructing, overseeing,
            and maintaining Information Technology (IT) Projects. My experience
            ranges from developing transactional computing applications to
            managing intricate Enterprise Cloud Networks and Virtual Servers.
            Over the years, I have refined my technical skills and can offer
            proficient management services for a wide array of IT projects. I
            have worked with several Companies and organisations including the
            United Nations, Goverment Ministries, Non-Govermental Organisations.
          </p>
          <p>
            Let's embark on a journey to transform ideas into impactful digital
            solutions that resonate with your audience and drive business
            success. Together, we can innovate and thrive in today's dynamic
            digital landscape.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Aboutme;
