import React from "react";
import { Link, Outlet } from "react-router-dom";
import Styles from "./styles.module.css";

import Footer from "../footer";
import NavBar from "../navbar/navbar";


function Layout2() {
  return (
    <div>
      <NavBar />
      <div className={Styles.container}>
        <div className={Styles.column1}>
          {data.map((menulink, index) => (
            <li key={index}>
              <Link to={menulink.link}>{menulink.name}</Link>{" "}
            </li>
          ))}{" "}
        </div>
        <div className={Styles.column2}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout2;

const data = [
  { link: "aboutme", name:"About Us" },
  { link: "Projects" , name:"Projects"},
  { link: "Techstack", name:"Techstack" },
  { link: "hardwaresupport", name:"Hardware Support" },
  { link: "contactme", name:"Contact Me" },
];
